import React, { useState, useEffect } from 'react';
import './NavBar.css';
import vw from '../../images/redesignLogo2.jpg';
import { useNavigate } from 'react-router-dom';
import { RiLoginCircleFill } from 'react-icons/ri';
import { FaWhatsappSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { VscAccount } from 'react-icons/vsc';
import { RiAccountPinCircleLine } from 'react-icons/ri';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  const loggedIn = JSON.parse(localStorage.getItem('isLoggedIn')); ;

  const handleBactToHome = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMainDataPlanPage = () => {
    toggleMenu();
    setIsMenuOpen(false)
    navigate('/data-plan');
  };
  const handleDataPlanPage = () => {
    toggleMenu();
    setIsMenuOpen(false)
    navigate('/data-plan');
  };
  const handleCorporateSolution = () => {
    toggleMenu();
    setIsMenuOpen(false)
    navigate('/corperate-products');
  };
  const handleDataPlanPageSupport = () => {
    toggleMenu();
    setIsMenuOpen(false)
    navigate('/vitel-wireless-support');
  };
  const handleEcommercePage = () => {
    toggleMenu();
    setIsMenuOpen(false)
    navigate('/e-commerce');
  };

  const handleLogOut = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('subscriberInformation');
  };
  const handleFindStore = ()=>{
    navigate("/find-vitel-store")
    setIsMenuOpen(false)
  }

  const handleFamilySafety = (()=>{
    navigate("/family-safety")
    setIsMenuOpen(false)
  })

  return (
    <div className="parentContainer">
      <div className="lowerNavEle">
        <div className="companyLogoDiv" onClick={handleBactToHome}>
          <img src={vw} alt="" />
        </div>
        <div className="navItemDiv">
          <ul>
            {/* <Link to="/data-plan" className="navLinkStyl" style={{ color: 'white' }}>
              <li onClick={handleMainDataPlanPage}>Data</li>
            </Link> */}

            <Link to="/data-plan" style={{ color: 'white', whiteSpace: 'nowrap' }}>
              <li onClick={handleDataPlanPage}>Voice / Data Plan</li>
            </Link>
            <Link to="/e-commerce" style={{ color: 'white' }}>
              <li>Devices</li>
            </Link>

            <Link to="/family-safety" style={{ color: 'white', whiteSpace: 'nowrap' }}>
              <li onClick={handleFamilySafety}>Family Safety / VAS</li>
            </Link>

            <Link to="/corperate-products" style={{ color: 'white', whiteSpace: 'nowrap' }}>
              <li>Corporate Solutions</li>
            </Link>

            <Link to="/vitel-wireless-support" style={{ color: 'white' }}>
              <li>Support</li>
            </Link>

            <Link to="/find-vitel-store" style={{ color: 'white', whiteSpace: 'nowrap' }}>
              <li>Find Store</li>
            </Link>
          </ul>
          <ul className="search">
            {loggedIn === true ? null : (
              <li>
                <Link to="/subscriber-login" className="Loginlink">
                  <RiLoginCircleFill className="accountIcon" /> Login
                </Link>
              </li>
            )}

            {loggedIn === true && (
              <li>
                <Link to="/customer/account/subscriber" className="Loginlink">
                  <RiAccountPinCircleLine className="accountIcon" />
                  My Account
                </Link>
              </li>
            )}
            <li className="supportDivNav">
              <FaWhatsappSquare className="whatsappIcon" />
              <div className="supportNum">
                <span className="justNumber">07077011111</span> 24/7 support
              </div>
            </li>
          </ul>
        </div>
        <div className="harmburgerDiv">
          <span onClick={toggleMenu}>{isMenuOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}</span>
        </div>

        {isMenuOpen && (
          <div className="MobileNavItemDiv">
            <ul>
              <li onClick={handleMainDataPlanPage}>Voice / Data Plan</li>
              <li onClick={handleEcommercePage}>Devices</li>
              <li onClick={handleFamilySafety}>Family Safety / VAS</li>
              <li onClick={handleCorporateSolution}>Corporate Solutions</li>
              <li onClick={handleDataPlanPageSupport}>Support</li>
              <li onClick={handleFindStore}>Find Store</li>
            </ul>
            <ul className="search">
              {loggedIn === true ? null : (
                <li>
                  <Link to="/subscriber-login" className="Loginlink" onClick={toggleMenu}>
                    <RiLoginCircleFill className="accountIcon" /> Login
                  </Link>
                </li>
              )}

              {loggedIn === true && (
                <li>
                  <Link to="/customer/account/subscriber" className="Loginlink" onClick={toggleMenu}>
                    <RiAccountPinCircleLine className="accountIcon" />
                    My Account
                  </Link>
                </li>
              )}
              <li className="supportDivNav">
                <FaWhatsappSquare className="whatsappIcon" />
                <div className="supportNum">
                  <span className="justNumber">07077011111</span> 24/7 support
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
