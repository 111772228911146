import React, { useState } from 'react';
import './SideBar.css';
import { BiBookAlt, BiHome, BiMessage, BiSolidReport, BiStats, BiTask, BiHelpCircle } from 'react-icons/bi';
import { MdDataUsage } from 'react-icons/md';
import { FaRegHeart, FaShoppingBag } from 'react-icons/fa';
import { MdEditNotifications } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';

import { ImProfile } from 'react-icons/im';

const SideBar = ({ onMenuItemClick, activeMenuItem }) => {
  const subscribInfoRaw = localStorage.getItem('subscriberInformation');
  const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
  const handleLogOut = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('subscriberInformation');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktopMenuopen, setIsDesktopMenuopen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const generateAlphaNumericId = (length = 20) => {
    const chars = '@ABCDEFGHIJKLMNOPQRS!TUVWXYZabcdefghi_jklmnop&qrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }

    return result;
  }

  return (
    <div className="menuContain">
      <div className={isMenuOpen ? 'expandHamburgerdiv' : 'hamburgerDivdashbod'}>
        <span>{isMenuOpen ? <AiOutlineClose onClick={closeMenu} /> : <GiHamburgerMenu onClick={openMenu} />}</span>
      </div>
      <div className={`menu ${isMenuOpen ? 'show-menu' : ''}`}>
        <div className={isMenuOpen ? 'show-menu_content' : 'menu-list'}>
          <Link
            to=""
            className={`item ${activeMenuItem === 'dashboard' ? 'active' : ''}`}
            onClick={() => {
              onMenuItemClick('dashboard');
              closeMenu();
            }}
          >
            <BiHome className="icon" />
            Manage Account
          </Link>
          {/* <a
          href="#"
          className={`item ${activeMenuItem === 'borrowAirtime' ? 'active' : ''}`}
          onClick={() => onMenuItemClick('borrowAirtime')}
        >
          <BiTask className="icon" />
          Borrow Airtime/Data
        </a> */}
          <div className="mobileprofilediv">
            <Link
              to={`profile/${subscribInfoProcessed.customerId}/${generateAlphaNumericId()}`}
              className={`item ${activeMenuItem === 'userProfile' ? 'active' : ''}`}
              onClick={() => {
                onMenuItemClick('userProfile');
                closeMenu();
              }}
            >
              <ImProfile className="icon" />
              My Profile
            </Link>
          </div>

          <Link
            to={`quick-top-up/${subscribInfoProcessed.customerId}/${generateAlphaNumericId()}`}
            className={`item ${activeMenuItem === 'quickTopUp' ? 'active' : ''}`}
            onClick={() => {
              onMenuItemClick('quickTopUp');
              closeMenu();
            }}
          >
            <BiTask className="icon" />
            Quick Top Up
          </Link>
          <a
          href="#"
          className={`item ${activeMenuItem === 'usageHistory' ? 'active' : ''}`}
          onClick={() => onMenuItemClick('usageHistory')}
        >
          <MdDataUsage className="icon" />
          You Think You Know
        </a>
          {/* <a
          href="#"
          className={`item ${activeMenuItem === 'manageSubscription' ? 'active' : ''}`}
          onClick={() => onMenuItemClick('manageSubscription')}
        >
          <BiSolidReport className="icon" />
          Manage Subscription
        </a> */}
          <Link
            to={`transaction-history/${subscribInfoProcessed.customerId}/${generateAlphaNumericId()}`}
            className={`item ${activeMenuItem === 'TransactionHistory' ? 'active' : ''}`}
            onClick={() => {
              onMenuItemClick('TransactionHistory');
              closeMenu();
            }}
          >
            <BiStats className="icon" />
            Transaction History
          </Link>
          <Link
            to={`wish-list/${subscribInfoProcessed.customerId}/${generateAlphaNumericId()}`}
            className={`item ${activeMenuItem === 'myWishList' ? 'active' : ''}`}
            onClick={() => {
              onMenuItemClick('myWishList');
              closeMenu();
            }}
          >
            <FaRegHeart className="icon" />
            My WishList
          </Link>
          {/* <a
          href='#'
          className={`item ${activeMenuItem === "sosContacts" ? "active" : ""}`}
          onClick={() => onMenuItemClick("sosContacts")}
        >
          <BiHelpCircle className='icon' />
          My SOS Contact List
        </a> */}
          <Link
            to={`notification/${subscribInfoProcessed.customerId}/${generateAlphaNumericId()}`}
            className={`item ${activeMenuItem === 'notifications' ? 'active' : ''}`}
            onClick={() => {
              onMenuItemClick('notifications');
              closeMenu();
            }}
          >
            <MdEditNotifications className="icon" />
            Notification
          </Link>
          <Link
            to="/e-commerce"
            className="item"
            onClick={() => {
              closeMenu();
            }}
          >
            <FaShoppingBag className="icon" />
            E-Commerce
          </Link>
          <Link to="/" className="linkStyle" onClick={handleLogOut}>
            <a href="#" className="logoutItem">
              <BiHome className="icon" />
              Logout
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
