import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { vitelWirelessAxios } from '../../utility/axios';
import axios from 'axios';
import './FindStore.css';
import { FaMagnifyingGlassLocation, FaEyeSlash, FaEye } from 'react-icons/fa6';
import useFetch from '../../utility/getFunction';
import { useForm } from 'react-hook-form';

const FindStore = () => {
  const [state, setState] = useState(0);
  const [lga, setLGA] = useState('');
  const [allData, setAllData] = useState([]);
  const [city, setCity] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [mapLocation, setMapLocation] = useState(null); // To store map latitude and longitude
  const [allvitelState, setAllVitelState] = useState([]);
  const [allvitelLGA, setAllVitelLGA] = useState([]);
  const [filteredLGAs, setFilteredLGAs] = useState([]);
  const [lgaId, setLgaId] = useState("");
  const [pendingSearch, setPendingSearch] = useState(false);
  const [pendingSearchState, setPendingSearchState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [noStateSelect, setNoStateSelect] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: area, isPending: areaIsPending } = useFetch(`partners/getLocationCities/${lgaId}`);

  useEffect(() => {
    const fetchStatesAndLGAs = async () => {
      try {
        const stateResponse = await vitelWirelessAxios.get('generals/states');
        const lgaResponse = await vitelWirelessAxios.get('generals/local_govt');
        setAllVitelState(stateResponse.data);
        setAllVitelLGA(lgaResponse.data);
      } catch (error) {
        console.error('Error fetching states and LGAs:', error);
      }
    };
    fetchStatesAndLGAs();
  }, []);

  const handleFilterLGA = (stateId) => {
    const stateLGA = allvitelLGA.filter((lga) => lga.stateId === stateId);
    setFilteredLGAs(stateLGA);
    setState(stateId);
  };

  const handleViewMap = (latitude, longitude) => {
    setShowMap(true);
    setMapLocation({ latitude, longitude });
  };


  const handleShowAddress = (() => {

    const targetElement = document.getElementById('premiumSection');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  })

  const handleHideViewMap = () => {
    setShowMap(false);
    setMapLocation(null);
  };

  const handleLocationSearch = async data => {

    setPendingSearch(true);
    const searchData = {
      locationName: data.locationName,
    };
    console.log('main>>>>>', searchData);
    await vitelWirelessAxios
      .post('/partners/locationStoreSearch', searchData)
      .then(res => {
        console.log('sucessData', res);
        setAllData(res.data)
        if (res.data.length != 0) {
          handleShowAddress()
        }

      })
      .catch(err => {
        console.log('err', err);
        setPendingSearch(false);
      });
  };


  const handleCheckSearch = (() => {
    if (state === 0) {
      setNoStateSelect(true)
    } else {
      handleLocationSearch2()
    }

  })

  const handleLocationSearch2 = async data => {
    setNoStateSelect(false)
    setPendingSearchState(true);
    const searchData = {

      stateId: state,
      lgaId: lgaId,
      cityId: city

    };
    // console.log('main>>>>>', searchData);
    await vitelWirelessAxios
      .post('/partners/locationStoreSearch', searchData)
      .then(res => {
        console.log('sucessData', res.data);
        setAllData(res.data)
        setPendingSearchState(false);
        if (res.data.length != 0) {
          handleShowAddress()
        }

      })
      .catch(err => {
        console.log('err', err);
        setPendingSearchState(false);
      });
  };

  return (
    <div className="findstoreparentdiv">
      <div className="findstoreHeaderparentdiv">
        <h2 className="finstoreText">
          Find Vitel Wireless Store <FaMagnifyingGlassLocation className="findIcon" /> near your Location
        </h2>

        <form className="firstInputSec" onSubmit={handleSubmit(handleLocationSearch)}>
          <input
            type="text"
            className="firstInputSec-input"
            placeholder="Enter Location, store name or area"
            {...register('locationName', { required: 'Please enter location name, store name or area' })}
          />
          <button type="submit" className="setBtnLocation">Search <span className='hideLOcat'>location</span> </button>
        </form>
        <span className="cum-error ErrorShow">{errors.locationName?.message}</span>

        <Formik
          initialValues={{ state: '', lga: '', city: '' }}
          onSubmit={() => handleLocationSearch2()}
        >
          {({ setFieldValue }) => (
            <Form className="findstoreparentFormdiv">
              <div className="findStoreFieldOrDiv"><h4>OR</h4></div>
              <div className="selectStoretitle cdtitle"><h4>Filter store location by: </h4></div>

              <div className="selectstorecity">
                <select
                  name="state"
                  onChange={(e) => {
                    const stateId = e.target.value;
                    setFieldValue('state', stateId);
                    handleFilterLGA(stateId);
                  }}
                >
                  <option value="">Select State</option>
                  {allvitelState.map((state) => (
                    <option key={state.stateId} value={state.stateId}>{state.name}</option>
                  ))}
                </select>

                <select name="lga" onChange={(e) => setLgaId(e.target.value)}>
                  <option>Select LGA</option>
                  {filteredLGAs.map((lga) => (
                    <option key={lga.lgaId} value={lga.lgaId}>{lga.name}</option>
                  ))}
                </select>

                <select name="city" onChange={(e) => setCity(e.target.value)}>
                  <option>Select City</option>
                  {area && area.map((city) => (
                    <option key={city.cityId} value={city.cityId}>{city.cityName}</option>
                  ))}
                  {area?.length === 0 && !areaIsPending && <option disabled>No store location here yet</option>}
                </select>

                <button onClick={handleCheckSearch} 
                className="setBtnLocation">Filter location</button>
              </div>
              {noStateSelect && <p className="cum-error">Please select at least a State</p>}
              {allData.length === 0 && pendingSearchState && <div>No result found</div>}
            </Form>
          )}
        </Formik>
      </div>

      <div className="locationListParent">
        <div className="locationList">
          <div className="findStoresectionHeader">
            <h3>Vitel Wireless store Location Near You</h3>
            <p>Meeting our customers where they are</p>
          </div>

          {showMap ? (
            <div className="mapContainer">
              <iframe
                title="Google Map"
                src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyCHuzJphZLHbAkH60YvSMelhKInZ-fizHk&center=${mapLocation.latitude},${mapLocation.longitude}&zoom=15`}
                width="100%"
                height="700"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              ></iframe>
              <button onClick={handleHideViewMap}>Hide map view <FaEyeSlash className="viewIcon" /></button>
            </div>
          ) : (
            <div className="locationtablediv">
              <table className="table" id="premiumSection">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Store Name</th>
                    <th>Store Type</th>
                    <th>State Name</th>
                    <th>LGA</th>
                    <th>City</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((data, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td>{data.storeName}</td>
                      <td>{data.storeType}</td>
                      <td>{data.stateName}</td>
                      <td>{data.lgaName}</td>
                      <td>{data.cityName}</td>
                      <td>{data.address}</td>
                      <td>
                        <button onClick={() => handleViewMap(data.latitude, data.longitude)}>
                          View Map <FaEye className="viewIcon" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindStore;