import { Link } from "react-router-dom";
import "./vasSection.css";


const  VasSection = (()=>{

    const handleClickOgaApp = () => {
        window.location.href = 'http://197.210.166.58:4006/#/oga-app';
        };


        const handleClickSecureMe = ()=>{

            window.location.href = "http://197.210.166.58:4006/#/secureme-app"
        }

        const handleClickVerifyJobs = ()=>{

            window.location.href = "http://www.verifiedjobs.info"
        }
        

    return(
         <>
       

<section className="dd_heading_all">

      
           <div className="dd_heading">
  <h2 className="text-center" style={{color:"white"}}>Value Added Services</h2>
{/* <h3 className="text-center">Designed by : <strong>Dhruval Desai</strong></h3> */}
</div>
<main className="page-content">
   
    
   

  <div className="d_card" onClick={handleClickOgaApp}>
  <div className="content">
        
        <h2 className="heading">Oga App</h2>
        <p className="data-content">Is a mobile app and web tool built to improve honesty at the workplace..
    </p>
      </div>
   
  </div>
  <div className="d_card" >
    
    <div className="content">
      <h2 className="heading">Oga-Boiz</h2>
      <p className="data-content">Is used by employees as companion tool to Oga-App. After creating your Oga-App account, 
        you will need to require your staff to download Oga-Boiz for clock-in/clock-out.  </p>
    </div>
  </div>
  <div className="d_card" onClick={handleClickSecureMe}>
    <div className="content">
      <h2 className="heading">SecureMe</h2>
      <p className="data-content">
           SecureMe was built to help keep us all safer by tracking and recording 
           our movement only when activated by the individual.
           </p>
    </div>
  </div>
  <div className="d_card" onClick={handleClickVerifyJobs}>
    <div className="content">
      <h2 className="heading">Verify-jobs</h2>
      <p className="data-content">
      Is built to increase the level of Truthfulness when employing a new domestic or professional 
      staff. 
        </p>
    </div>
  </div>
</main>
</section>
        
        
        </>
    )
})

export default VasSection