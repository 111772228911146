import { useNavigate } from "react-router-dom";
// import familyImage from "../../images/bannerBlack.png";
import "./familyBanner.css";
import familyImage from "../../images/FAMILY-SAFT- BANNER1.png"
import ipadImage from "../../images/HOMFAM-IPAD.png";
import phoneImage from "../../images/HOMFAM-MOBILE.png"




const FamilySafetyBanner = (()=>{


    const navigate = useNavigate();

    const navigateFamilySafety = (()=>{
 
     navigate("/family-safety")
    })
  

    return(
       
       <>
     <div className="ban_sec"  >
		<div className="">
			<div className="ban_img">
	<img src={familyImage} alt="banner" border="0"/>
	<img className="ipadSEc" src={ipadImage} alt="banner" border="0"/>
	<img className="phoneSEc" src={phoneImage} alt="banner" border="0"/>
				<div className="ban_text">
					{/* <strong>
						<span>Family Safety</span><br/> 
					</strong>
					<p>Every SIM card comes with free Family Safety features <br/>
						behavioral, and physical health </p> */}
					<a className="learDrop-set"  onClick={navigateFamilySafety}>Learn More</a>
				</div>
			</div>
		</div>
	</div>
 </>
    )
    
})

export default FamilySafetyBanner
